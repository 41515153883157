import {
  Button, Dialog, DialogTitle, Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import type { IAreaCompParam } from '@/containers/SearchFlightTab/SearchDialog/index.d';
import { useAppSelector } from '@/utils/hook';
import SearchFlightResultItem from '@/containers/SearchFlightTab/SearchFlightResultItem';
import SearchBusResultItem from '@/containers/Bus/SearchBusTab/SearchBusResultItem';
import uniqueId from 'lodash/uniqueId';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';

const RecentArea = ({
  setClose, type, vehicleType, isDesktop, lang,
}: IAreaCompParam) => {
  const [openAlert, setOpenAlert] = React.useState(false);
  const areas = useAppSelector((state) => state.area.recentAreas);
  const [hoverIndex, setHoverIndex] = React.useState<any>('');

  if (areas.length === 0) {
    return null;
  }

  const dialogContent = `Địa điểm này đã được chọn ở mục ${type === 'from' ? '"Nơi đến"' : '"Nơi đi"'}. Bạn vui lòng chọn địa điểm khác nhé`;
  const ResultItem = vehicleType && vehicleType === 'bus'
    ? SearchBusResultItem
    : SearchFlightResultItem;
  return (
    <Stack
      spacing={1}
      sx={{ margin: 0 }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          marginBottom: pr`8px`,
          color: style.color.black8,
          paddingX: '16px',
        }}
      >
        {lang?.SearchBar?.flightsTab?.areaResult?.recentSearches || 'TÌM KIẾM GẦN ĐÂY'}
      </Typography>
      {areas?.map((item, index) => (
        <ResultItem
          type={type}
          setClose={setClose}
          key={uniqueId()}
          data={item}
          isRecent
          index={index}
          hoverIndex={hoverIndex}
          onMouseEnter={isDesktop ? setHoverIndex : undefined}
          lang={lang}
        />
      ))}
      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="same-location"
        aria-describedby="Same location chosen"
      >
        <DialogTitle id="same-location">{dialogContent}</DialogTitle>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: pr`16px`,
          }}
        >
          <Button
            style={{
              background: '#CED4DA',
              width: '90%',
            }}
            onClick={() => setOpenAlert(false)}
          >
            Xong
          </Button>
        </div>
      </Dialog>
    </Stack>
  );
};

export default RecentArea;
