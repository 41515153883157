import { ILangObj } from '@/slices/goyoloSeo/sliceType';
import React from 'react';

export interface SearchDialogProps {
  type: LocationSearchType,
  setClose: () => void
}

export interface ISearchFromInputParams {
  type: LocationSearchType;
  isDesktop?: boolean;
  defaultValue?: {
    label: React.ReactNode, value: string
  }
}

export enum LocationSearchType{
  FROM = 'from',
  TO = 'to',
}

export interface IAreaCompParam {
  type: LocationSearchType,
  vehicleType?: 'bus' | 'flight'
  setClose: () => void
  hoverIndex?: number
  isDesktop?: boolean;
  lang?: ILangObj;
}
