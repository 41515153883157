// import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import SearchFlightResultItem from '@/containers/SearchFlightTab/SearchFlightResultItem';
import { style } from '@/styles/globals';
import {
  Button, Dialog, DialogTitle, Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { uniqueId } from 'lodash';
import React from 'react';
import areas from '../../../public/popularAreas';
import type { IAreaCompParam } from '@/containers/SearchFlightTab/SearchDialog/index.d';
import { pr } from '@/utils/pxToRem';
import SearchBusResultItem from '@/containers/Bus/SearchBusTab/SearchBusResultItem';

const PopularArea = ({
  setClose, type, vehicleType, isDesktop, lang,
}: IAreaCompParam) => {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [hoverIndex, setHoverIndex] = React.useState<string>('');

  const ResultItem: any = vehicleType && vehicleType === 'bus'
    ? SearchBusResultItem
    : SearchFlightResultItem;

  const dialogContent = `Địa điểm này đã được chọn ở mục ${
    type === 'from' ? '"Nơi đến"' : '"Nơi đi"'
  }. Bạn vui lòng chọn địa điểm khác nhé`;

  return (
    <Stack
      spacing={1}
      sx={{
        margin: 0,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          marginBottom: pr`8px`,
          marginTop: '1.5em',
          color: style.color.black8,
          paddingX: '16px',
        }}
      >
        {lang?.SearchBar?.flightsTab?.areaResult?.popular || 'THÀNH PHỐ SÂN BAY PHỔ BIẾN'}
      </Typography>
      {areas?.map((item, index) => (
        <ResultItem
          index={index}
          type={type}
          setClose={setClose}
          key={uniqueId()}
          data={item}
          hoverIndex={hoverIndex}
          onMouseEnter={isDesktop ? setHoverIndex : undefined}
          lang={lang}
        />
      ))}

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="same-location"
        aria-describedby="Same location chosen"
      >
        <DialogTitle id="same-location">{dialogContent}</DialogTitle>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: pr`16px`,
          }}
        >
          <Button
            style={{
              background: '#CED4DA',
              width: '90%',
            }}
            onClick={() => setOpenAlert(false)}
          >
            Xong
          </Button>
        </div>
      </Dialog>
    </Stack>
  );
};

export default PopularArea;
